import { SignService } from '@/Services/SignService'
import { OfferStatusSignatures, TransactionSignatures } from '@/modules/Signatures'
import { actionSignature } from '@/Methods/ActionSignature'
import Store from '@/store'
import Decimal from 'decimal.js'

/* eslint-disable camelcase */
export let reject = (action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    let signService = new SignService(key, localStorage.user_id)
    let tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
    let tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.unlockFundsOnAccount), operation_id)
    let final_option = {
      action_set_offer_status_by_operation_id: tempOption1,
      transfer: tempOption3
    }
    resolve(final_option)
  })
}

export let accept = (block_option, action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    try {
      const { currency_type: currencyName, amount } = block_option?.amount || {}
      const codeCurrency = Store.state.tokensNameMap[currencyName]?.code
      const currencyBalance = Store.getters.getMainWalletBalance[codeCurrency]
      console.log({ currencyBalance, amount })
      if (!new Decimal(currencyBalance || 0).gte(amount)) throw new Error('Упс')
      const signService = new SignService(key, localStorage.user_id)
      const tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
      const tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.transferFunds), operation_id)
      const sign_block_option = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.lockFundsOnAccount), block_option)
      const final_option = {
        action_set_offer_status_by_operation_id: tempOption1,
        transfer: tempOption3,
        txn_funds_lock_input: sign_block_option
      }
      resolve(final_option)
    } catch (err) {
      console.log({ err })
    }
  })
}

export let cancel = (action_set_offer_status_by_operation_id, operation_id, key) => {
  return new Promise(function (resolve, reject) {
    let signService = new SignService(key, localStorage.user_id)
    let tempOption1 = signService.createSignedRequest(actionSignature(OfferStatusSignatures.channel, OfferStatusSignatures.chaincode, OfferStatusSignatures.action.addKeyValue), action_set_offer_status_by_operation_id)
    let tempOption3 = signService.createSignedRequest(actionSignature(TransactionSignatures.channel, TransactionSignatures.chaincode, TransactionSignatures.action.unlockFundsOnAccount), operation_id)
    let final_option = {
      action_set_offer_status_by_operation_id: tempOption1,
      transfer: tempOption3
    }
    resolve(final_option)
  })
}
/* eslint-enable camelcase */
